<template>
<div class="container">
    <h2>Contact Us</h2>
    <hr>
    <div class="row">
        <div class="col-sm-1">
            <p>
                Email:
            </p>
        </div>
        <div class="col">
            <p>
                <a href="mailto:hello@reinveintingwheel.com">hello@reinveintingwheel.com</a>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-1">
            <p>
                Github:
            </p>
        </div>
        <div class="col">
            <p>
                <a href="https://github.com/keshav-sarraf/rw-frontend">rw-frontend</a>
            </p>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-1">
            <p>
                Instagram:
            </p>
        </div>
        <div class="col">
            <p>
                <a href="https://www.instagram.com/simple.explainations/">@simple.explainations</a>
            </p>
        </div>
    </div>
</div>
</template>
